<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>
          PCM Profiel
        </h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" v-if="loading">
        <dashboard-own-scan-loading scan />
      </v-col>

      <v-col cols="12" v-else-if="rapportAvailable">
        <v-row>
          <v-col cols="12" class="text-center py-0">
            <v-btn color="primary" block @click="downloadRapport" :loading="downloadLoading" :disabled="downloadLoading">Download volledig profiel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-else>
        <dashboard-own-scan-not-filled scan />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DashboardOwnScanNotFilled from "@/pages/dashboard/own/notfilled";
import DashboardOwnScanLoading from "@/pages/dashboard/own/loading";
import pdf from 'vue-pdf'
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "dashboardOwnScanPCM",
  components: {DashboardOwnScanLoading, DashboardOwnScanNotFilled, pdf},
  data() {
    return {
      loading: true,
      rapportAvailable: false,
      imageUrl: "",
      downloadUrl: "",
      downloadLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      token: "auth/accessToken",
      scan: "user/scan",
      user: "user/user"
    }),
  },
  mounted() {
    if (this.scan.pcm.access && this.scan.pcm.completed) {
      this.rapportAvailable = true;

      this.downloadUrl = process.env.VUE_APP_API_URL + "scan/pcm/download.pdf?token=" + this.token;

      this.loading = false;
    } else {
      this.loading = false;
      this.rapportAvailable = false;
    }
  },
  methods: {
    stopLoading() {
      this.downloadLoading = false;
    },
    downloadRapport() {
      this.downloadLoading = true;

      window.open(this.downloadUrl, "_blank");

      setTimeout(this.stopLoading, 2000);
    },
  }
};
</script>
